.design-styles {
  overflow: auto;
  position: relative;
}

.design-styles .column-one {
  float: left;
  width: 30%;
}

.design-styles .column-two {
  padding: 4.5em 0 0 0;
  text-align: center;
}

.design-styles .design-style-item {
  cursor: pointer;
  display: inline-block;
  margin: 0 2em 2em 2em;
}

.design-styles .design-style-item .thumb {
  background-position: center;
  background-repeat: no-repeat;
}

.edit-options {
  padding-top: 1em;
  margin: 2em;
  max-width: 24em;
  height: 100%;
  position: fixed;
  pointer-events: all;
  z-index: 1;
  width: 100%;
}

.change-color-btn {
  pointer-events: all;
  margin: 2em;
  z-index: 1;
}

.apply-btn {
  pointer-events: all;
  z-index: 1;
  float: right;
}

.design-step .list {
  flex-wrap: wrap;
  overflow: auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.design-step .item {
  align-items: stretch;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.design-step .thumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.design-step .name {
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.design-container {
  display: flex;
  flex-direction: row;
}

.jersey-column {
  flex: 1 0 30%;
}

.thumb-column {
  flex: 1 0 70%;
}

.colors-selector {
  display: flex;
  flex-direction: column;
  margin: 1em 0 2em 0;
}

.header2 {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.header2Text {
  color: #fff;
  font-size: 1.5em;
  padding: 0.5em 1em;
  text-transform: uppercase;
  text-align: center;
}

.img-preview {
  width: 260px;
  height: 260px;
}

.text-center {
  text-align: center;
}

.spinner-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbLoading {
  color: #f0f0f0;
  font-size: 50px;
}

.divider {
  border-bottom: 1px solid #e7e4e0;
  margin-bottom: 1em;
}

.design-step-header {
  text-align: center;
  top: 90px !important;
  width: 100%;
  padding-bottom: 20px !important;
  display: grid;
  align-content: end;
  height: 50px;
}

.loader-text {
  text-transform: capitalize;
  display: none;
  line-height: 23px;
  height: 23px;
}

.loader-text > span.icon {
  margin-right: 5px;
}

.selected-thumb {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(255,255,255, 0.7);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.selected-thumb img {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.selected-item {
  position: fixed;
  top: 62%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;
}

.not-clickable,
.not-clickable button,
.not-clickable .colors-selector,
.not-clickable > button {
  cursor:default !important;
  pointer-events: none !important;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@media (min-width: 751px) and (max-width: 1024px) {
  .jersey-column {
    flex: 1 0 40%;
  }
  .thumb-column {
    flex: 1 0 60%;
  }
  .edit-options {
    width: unset !important;
    position: relative;
  }
}

@media only screen and (max-width: 750px) {
  .design-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .jersey-column, .thumb-column {
    width: unset !important;
  }
  .jersey-column {
    padding: 0 3em 0 3em;
  }
  .edit-options {
    position: relative;
    max-width: unset;
    margin: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .design-step .design-container .item .thumb {
    height: 27% !important;
  }
}

@media only screen and (max-width: 875px) {
  .gray-goods .item .name {
    font-size: 1.25em !important;
    margin: unset !important;
  }
}

@media only screen and 
(min-device-width: 320px) and 
(max-device-width: 812px) and 
(-webkit-min-device-pixel-ratio: 3) and 
(orientation: landscape) {
  .design-step .name, .text-center {
    margin: 4em 0 0 0 !important;
  }
}