.customize {
  display: flex;
  flex-direction: column;
  padding-bottom: 68px;
  margin-bottom: 1em;
}

.customize.no-footer {
  padding-bottom: 0px;
}

/* To keep prevent canvas stretching we do not hide by display none */
.customize-hidden {
  height: 100%;
  left: 200%;
  position: absolute;
  width: 100%;
}

.customize .customize-body {
  display: flex;
  flex-direction: row;
}

.customize .customize-footer {
  padding: 1em;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 -2px 5px -1px rgb(0 0 0 / 20%);
  background-color: #FFFF;
}

.customize .canvas-header {
  bottom: 0.5em;
  color: #d0d0d0;
  font-family: 'proxima_novalight';
  font-size: 1em;
  left: 50%;
  pointer-events: none;
  position: absolute;
  text-transform: uppercase;
  transform: translateX(-50%);
  white-space: nowrap;
}

.customize .canvas-wrapper {
  flex: 2;
  min-height: 10px;
  min-width: 10px;
  position: relative;
}

.customize .customize-tabs {
  margin: 2em;
  max-width: 24em;
}

.customize .roster-grid {
  margin: 2em;
  flex: 3;
  min-height: 10px;
}

.customize .add-roster-button {
  bottom: 2em;
  position: absolute;
  right: 0;
  height: 38px;
}

.customize .save-design-button {
  bottom: 2em;
  position: absolute;
  right: 12em;
  height: 38px;
}

.customize .save-design-button .label {
  margin: 0 0.5em 0 0;
  text-transform: uppercase;
}

.customize .add-roster-button .label {
  margin: 0 0.5em 0 0;
  text-transform: uppercase;
}

/* Hide hud and add roster button */
.main.active-step-add-roster .customize .add-roster-button,
.main.active-step-add-roster .customize .customize-hud,
.main.active-step-add-roster .customize .customize-tools-hud,
.main.active-step-review .customize .add-roster-button,
.main.active-step-review .customize .customize-hud,
.main.active-step-review .customize .customize-tools-hud {
  display: none;
}

.customize .customize-footer button {
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customize .customize-footer button .label {
  flex: none;
}

/* Responsive */

@media only screen and (max-width: 1100px) {
  .customize .roster-grid {
    margin: 1em;
  }
}

@media only screen and (max-width: 1000px) {
  .active-step-add-roster .customize .customize-body {
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .customize .canvas-header {
    font-size: 0.8em;
  }

  .customize .customize-tabs {
    margin: 1em;
  }
}

@media only screen and (max-width: 700px) {
  .customize .customize-body {
    flex-direction: column;
  }

  .customize .canvas-wrapper {
    flex: 3;
  }

  .customize .customize-tabs {
    flex: 2;
    margin: 0;
    max-width: none;
    min-height: 10px;
  }

  .customize .customize-tabs .name-number .field {
    margin: 0.5em;
  }

  .customize .add-roster-button {
    bottom: 1em;
    font-size: 0.8em;
    line-height: 0.8em;
    padding: 1.2em;
    right: 1em;
  }

  .customize .add-roster-button .icon {
    display: none;
  }

  .customize .add-roster-button .label {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .customize .roster-grid {
    margin: 0;
  }
}