.customize-hud {
  align-items: start;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 1em;
}

.customize-hud .title {
  font-size: 1.2em;
  text-transform: uppercase;
}

.customize-hud .label {
  margin: 0 0 0 0.5em;
}

.customize-hud .design-style {
  color: #575757;
}

.customize-hud .price {
  color: #009900;
}

.customize-hud .button {
  background-color: #fff;
  border-radius: 0.2em;
  font-size: 1.75em;
  padding: 0.25em;
  margin: 0.25em;
  pointer-events: all;
}

.customize-hud .button .label {
  margin: 0;
}

@media only screen and (max-width: 800px) {
  
}

/* Smaller than 435 (Phone) */
@media only screen and (max-width: 435px) and (orientation: portrait), (max-height: 435px) and (orientation: landscape) {
  .customize-hud {
    top: 0.5em;
  }

  .customize-hud .title {
    font-family: 'proxima_novaregular';
    font-size: 0.8em;
  }

  .customize-hud .button {
    font-size: 1.5em;
    margin: 0.5em 0.5em 0 0.5em;
    padding: 0.5em;
  }
}