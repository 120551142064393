.roster-grid {
  display: flex;
  flex-direction: column;
}

.roster-grid .add-wrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em 0em 0.5em 0em;
}

.roster-grid .add-wrap .column-right {
  column-gap: 2em;
}

.roster-grid .add-wrap .player-count {
  font-family: 'proxima_novaregular';
  font-style: italic;
  margin: 0 1em 0 0;
}

.roster-grid .table-wrap {
  flex: 1;
  min-height: 10px;
  overflow: auto;
  padding: 1px;
}

.roster-grid table {
  border-collapse: collapse;
  width: 100%;
}

.roster-grid tr.active {
  border-style: solid;
  border-width: 1px;
}

.roster-grid tr:nth-of-type(odd),
.roster-grid tr:nth-of-type(odd) input {
  background-color: #f0f0f0;
}

.roster-grid tr input:focus {
  background-color: #fff6da !important;
}

/* .roster-grid tr input:invalid {
  background-color: #ff0000 !important;
  color: #fff !important;
} */

.roster-grid th {
  background-color: #fff;
  font-family: 'proxima_novalight';
  font-style: italic;
  font-weight: 600;
  padding: 0.5em;
  position: sticky;
  top: 0;
  z-index: 2;
}

.roster-grid th.ca,
.roster-grid td.ca {
  width: 3em;
}

.roster-grid th.name,
.roster-grid td.name {
  min-width: 6em;
}

.roster-grid th.number,
.roster-grid td.number {
  width: 6em;
}

.roster-grid th.quantity,
.roster-grid td.quantity {
  width: 6em;
}

.roster-grid th.button-column,
.roster-grid td.button-column {
  width: 4em;
}

.roster-grid th.size,
.roster-grid td.size {
  width: 4em;
}

.roster-grid td {
  padding: 1px 0;
}

.roster-grid th,
.roster-grid td {
  justify-content: center;
  text-align: center;
  white-space: normal;
}

.roster-grid tr td:not(:last-child),
.roster-grid tr th:not(:last-child) {
  border-right: 1px solid #c0c0c0;
}

.roster-grid input,
.roster-grid .display-input {
  border: 0 none;
  box-sizing: border-box;
  outline: none;
  padding: 0.5em;
  width: 100%;
}

.roster-grid td.border-item {
  border: 2px solid red !important; 
  text-decoration: line-through; 
}

.roster-grid button {
  background-color: transparent;
  border: 0 none;
  font-family: 'proxima_novalight';
  font-style: italic;
  font-weight: 600;
  padding: 0.6em;
}

.roster-grid .add-button {
  align-self: flex-start;
  background-color: #fff;
  border: 0 none;
  padding: 0.5em;
  text-transform: uppercase;
}

.roster-grid .preview-button,
.roster-grid .remove-button {
  width: 100%;
}

.roster-grid button:disabled {
  color: transparent;
}

.roster-grid .import-btn {
  margin-left: auto;
  font-style: normal;
}

.invalid-row td.name > input,
.invalid-row td.number > input,
.invalid-row td.size > input,
.invalid-row td.quantity > input,
.invalid-row td.ca > input  {
  opacity: 0.5;
}

.valid-row td.name > input,
.valid-row td.number > input,
.valid-row td.size > input,
.valid-row td.quantity > input,
.valid-row td.ca > input  {
  text-decoration:none;
  opacity: 1;
}

.invalid-row td.name .display-input,
.invalid-row td.number .display-input,
.invalid-row td.size .display-input,
.invalid-row td.quantity .display-input,
.invalid-row td.ca .display-input {
  opacity: 0.5;
}

.valid-row td.name .display-input,
.valid-row td.number .display-input,
.valid-row td.size .display-input,
.valid-row td.quantity .display-input,
.valid-row td.ca .display-input {
  text-decoration:none;
  opacity: 1;
}

.alert-message {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  font-family: 'proxima_novaregular';
}

.names-msg {
  font-family: 'proxima_novaregular';
}

.add-wrap .column-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.add-wrap .column-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.roster-grid .footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.roster-grid .footer .download-link {
  padding-top: 0.5em;
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 550px) {
  .roster-grid {
    padding: 1em;
  }
  
  .roster-grid th,  
  .roster-grid tr {
    font-size: 0.9em;
  }

  .roster-grid input,
  .roster-grid .display-input {
    padding: 0.8em 0.5em;
  }

  .roster-grid .preview-button,
  .roster-grid .remove-button {
    padding: 0.9em 0.6em;
  }

  .roster-grid .add-wrap {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 1.25em;
  }

  .roster-grid .add-wrap .column-right {
    width: 100%;
  }
}