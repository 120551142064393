.patternCombo {

}

.patternComboHidden {
  composes: patternCombo;
  display: none;
}

.header {
  color: #c0c0c0;
  font-family: 'proxima_novaregular';
  font-size: 0.9em;
  padding: 7px 7px 0 7px;
}

.headerHidden {
  composes: header;
  display: none;
}

.body {
  display: flex;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 7px;
}

.colorChiclet {
  cursor: pointer;
  height: 63px;
  width: 63px;
  margin: 0;
  min-width: 63px;
}

.activeColorChiclet {
  composes: colorChiclet;
  border-radius: 1em;
}

.thumbChiclet {
  cursor: pointer;
  height: 63px;
  width: 63px;
  margin: 0;
  min-width: 63px;
}

.activeThumbChiclet {
  composes: thumbChiclet;
  border-radius: 1em;
}

.spacer {
  border-width: 0 1px;
  border-style: none solid;
  border-color: transparent #d0d0d0;
  height: 43px;
  margin: 10px;
  width: 43px;
}