.gray-goods {
  overflow: auto;
  height: 100%;
  padding: 50px 0 0 0;
  position: relative;
  width: 100%;
}

.gray-goods.centered {
  max-width: 1200px; 
  margin: 0 auto;
}

.gray-goods .list {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-around;
  overflow: auto;
  width: 100%;
}

.gray-goods .list .item {
  margin: 2em 0 2em 0;
  flex: 1 0 33%
}

.gray-goods .list .item,
.gray-goods .slider .item {
  cursor: pointer;
  pointer-events: all;
  text-align: center;
}

.gray-goods .item .thumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 260px;
  min-width: 260px;

  -webkit-transition: height 0.5s, min-width 0.5s;
  -moz-transition: height 0.5s, min-width 0.5s;
  -o-transition: height 0.5s, min-width 0.5s;
  transition: height 0.5s, min-width 0.5s;
  transition-timing-function: ease-in-out;
}

.gray-goods .item .thumb:hover {
  opacity: 0.75;
}

.gray-goods .active .item .thumb {
  height: 250px;
  min-width: 250px;
}

.gray-goods .item .name {
  color: #575757;
  font-size: 1.5em;
  margin: 0.5em 0 0 0;
  text-transform: uppercase;

  -webkit-transition: font-size 0.5s;
  -moz-transition: font-size 0.5s;
  -o-transition: font-size 0.5s;
  transition: font-size 0.5s;
  transition-timing-function: ease-in-out;
}

.gray-goods .active .item .name {
  font-weight: 600;
}

.mobile-list-display {
  padding: 1.5em 0 0 0;
  text-align: center;
}

.mobile-list-display > div.item { 
  cursor: pointer;
  display: inline-block;
  margin: 0 2em 2em 2em;
}

.no-items-message {
  color: #c0c0c0;
  font-size: 1.5em;
  padding: 3em 2em 2em 2em;
  text-align: center;
}

/* @media only screen and (max-width: 1100px) {
  .gray-goods .item .thumb {
    height: 200px;
    min-width: 200px;
  }
} */

@media only screen and (max-width: 875px) {
  /* .gray-goods .item .thumb {
    height: 150px;
    min-width: 150px;
  } */
  
  .gray-goods .item .name {
    font-size: 1.25em;
    margin: 3em 0 0 0;
  }
  .gray-goods .slider .active .name {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 825px) {
  .gray-goods .slider .item .thumb {
    height: 100px;
    min-width: 100px;
  }
  .gray-goods .slider .active .thumb {
    height: 150px;
    min-width: 150px;
  }

  .gray-goods .item .name {
    font-size: 1em;
    margin: 4.5em 0 0 0;
  }
  .gray-goods .slider .active .name {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 750px) {
  .gray-goods .list {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
  }

  .gray-goods .list .item .thumb {
    height: 200px;
    min-width: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .gray-goods .item .name {
    font-size: 1.2em;
    margin: 2em 0 0 0;
  }
}