.loginMain {    
  margin: 0 3em 3em 3em;
  max-width: 30em;
  min-width: 20em;
}

.message {
  font-family: 'proxima_novaregular';
  padding: 2em 0 0 0;
}

.planeIcon {
  font-size: 3em;
  padding: 0.5em;
}

.linkSentMessage {
  composes: message;
  padding: 0;
}

.errorMessageContainer {
  color: #fff;
  text-align: center;
  background-color: #d42b1e;
  padding: 0.5em;
  margin: 0 0 2em 0;
}

.errorMessage {
  font-family: 'proxima_novaregular';
}

.loginFailedHidden {
  display: none;
}

.loginButtonRed{
  background-color: #fff;
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1.5em;
  box-shadow: none;
}


.sideButton {
  width: 100px;
  margin: 0 auto;
  display: inline;
}

.buttonContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginMainTitle {
  font-size: 1.5em;
  font-weight: 300;
  text-shadow: 1px 1px 1px rgba(255,255,255,0.7);
  text-transform: uppercase;
  text-align: center;
  padding: 0.5em 0 1em 0;
}

.loginTitle {
  font-family: 'proxima_novaregular';
  font-size: 1em;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(255,255,255,0.7);
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 7%;
}

.inputContainer {
  padding-bottom: 2em;
  text-transform: uppercase;
}

.loginLabel {
  font-size: 1.25em;
  font-weight: 600;
  text-shadow: 1px 1px 1px rgba(255,255,255,0.7);
  text-transform: uppercase;
}

.loginField {
  align-items: stretch;
}

.loginFieldInvalid {
  composes: loginField;
}

.loginField label {
  font-family: 'proxima_novaregular';
  text-transform: uppercase;
}

.loginFieldInvalid input {
  border-color: #d42b1e;
}

.coachContactMain {    
  margin: 0 2em 2em 2em;
}

.parentcontent {
  display: flex;
  flex-direction: column;
}

.parentcontent .coachContactInput {
  margin: 0 0 1.7em 0;
  text-transform: uppercase;
}

.parentcontent .coachContactInput label {
  display: flex;
  justify-content: end;
  align-items: center;
  text-transform: uppercase;
  width: 40%;
  color: #575757;
  padding: 0 1em 0 0;
}

.parentcontent .coachContactField {
  align-items: stretch;
}

.parentcontent .coachContactField label {
  text-transform: uppercase;
}

.parentcontent .coachContactInput input {
  border: 1px solid #ccc;
  color: #575757;
  flex: 1 1;
  outline: none;
  padding: .5em;
  width: 60%;
}

.parentcontent .coachContactInput .textDanger {
  font-family: 'proxima_novaregular';
  color: red;
  font-style: italic;
  font-size: 0.8em;
  position:absolute;
  right: 30px;
  text-transform: none;
  margin-top: 2px;
}

.saveContactBtn {
  border-style: solid;
  border-width: 2px;
  padding: 0.5em 1.5em;
  box-shadow: none;
  height: 38px;
  min-width: 186px;
  margin-top: 1em;
}

.saveContactBtn span {
  color: #fff;
}

.saveContactBtn .icon {
  font-size: 1.2em;
}