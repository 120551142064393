.submittedOrders, .savedOrders {
  max-width: 100%;
  width: 70%;
  height: 80%;
}

.savedOrders, .submittedOrders .body {
  padding-bottom: 30px;
}

.importRosterPopup {
  width: 100%;
  max-width: 500px;
}

.importRosterPopup .modal>.body {
  padding-bottom: 1em;
}

.orderConfirmation {
  width: 100%;
  max-width: 500px;
}

.orderConfirmation .modal>.body {
  padding-bottom: 1em;
}

.selectCoachInfoPopup {
  width: 100%;
  max-width: 500px;
}

.customMsgPopup {
  width: 100%;
  max-width: 430px;
}

.faqPopup {
  width: 100%;
  max-width: 750px;
  height: 100%;
  max-height: 800px;
}

@media only screen and (max-width: 750px) {
  .savedOrders, .submittedOrders {
    width: 100%;
    height: 100%;
  }

  .savedOrders, .submittedOrders .body {
    padding-bottom: 0px;
  }

  .orderConfirmation,
  .importRosterPopup {
    width: 90%;
  }

  .customMsgPopup {
    width: 75% !important;
  }

  .faqPopup {
    max-width: 100%;
    max-height: 100%;
  }
}