.colorsCarousel {
  display: flex;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 7px;
}

.colorsCarouselHidden {
  composes: colorsCarousel;
  display: none;
}

.colorsCarouselDisabled {
  composes: colorsCarousel;
  opacity: 0.5;
}

.colorChiclet {
  cursor: pointer;
  height: 63px;
  width: 63px;
  min-width: 63px;
}