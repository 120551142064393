.selected {
  box-shadow: 0px 0px 5px #000;
  outline: 2px solid #fff;
  z-index: 2;
}

.checkmark {
  color: #fff;
  font-size: 2em;
  left: 0.5em;
  position: absolute;
  text-shadow: 0px 0px 2px #404040;
  top: 0.65em;
}

.checkmarkHidden {
  display: none;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 3;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.selectedChiclet {
  border: '15px solid black;'
}