.whiteHeaderWrapper {
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.whiteHeader {
  composes: flex-row from '../../../index.css';
  align-items: center;
  justify-content: space-between;
}

.whiteHeader img {
  margin-left: 1em;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.divider {
  font-size: 2.5em;
  font-family: 'proxima_novalight';
}

.userButton {
  composes: ui-button-clear from '../../../index.css';
  cursor: pointer;
  padding: 0.5em 1em;
}

/* Smaller than 435 (Phone) */
@media only screen and (max-width: 435px) {
  .userButton {
    font-size: 1.2em;
  }

  .wrapper button:nth-child(1) {
    margin-right: 1em !important;
  }
}