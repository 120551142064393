.orderItemHeader {
  composes: flex-row from '../../../index.css';
}

.orderItem {
  composes: flex-row from '../../../index.css';
  color: #999;
  cursor: pointer;
  font-family: 'proxima_novaregular';
  align-items: center;
  height: 80px;
}

.orderItem:hover {
  background-color: #f0f0f0;
}

.divider {
  border-bottom: 1px solid #e7e4e0;
  margin-top: 1em;
}

.column {
  composes: flex-one from '../../../index.css';
  padding: 0.5em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageColumn {
  composes: column;
  background-position: center;
  background-repeat: no-repeat;
  height: 70px;
  min-height: 70px;
  object-fit: contain;
}

.thumbListPreview {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 16.5%;
}

.header {
  composes: column;
  color: #575757;
}

.mobileHeader {
  composes: header;
  display: none;
}

.loadMore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.loadMoreButton {
  border: 0 none;
  color: #fff;
  height: 37px;
}

.noItemsFound {
  font-family: 'proxima_novaregular';
  color: #c0c0c0;
  font-size: 1.5em;
  padding: 3em 2em 2em 2em;
  text-align: center;
}

.copyBtn .copyLink {
  display: none;
}

.selectedOrder {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(0%);
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 80px;
  cursor: default !important;
  pointer-events: none !important;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #575757;
}

.selectedOrder span {
  text-transform: capitalize;
  font-weight: 600;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.selectedOrder i {
  margin-bottom: 3px;
  margin-right: 5px;
}

.noClick {
  cursor: default !important;
  pointer-events: none !important;
}

.thumbLoading {
  color: #f0f0f0;
  font-size: 50px;
}

.spinnerContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Smaller than 500 (Phone) */
@media only screen and (max-width: 500px) {
  .thumbContainer img {
    position: relative;
    padding: 1.5em;
    width: 60px;
  }
  .thumbContainer :nth-child(3) {
    z-index: 3;
    left: 40px;
    margin-top: -115px;
  }
  .thumbContainer :nth-child(2) {
    z-index: 4;
    left: 20px;
    margin-top: -115px;
  }
  .thumbContainer :nth-child(1) {
    z-index: 5;
    left: 0px;
  }

  .loadMore {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em;
    box-shadow: 0 -2px 5px -1px rgb(0 0 0 / 20%);
    background-color: #fff;
    margin-bottom: 0;
    z-index: 100;
  }

  .copyBtn button {
    display: none;
  }

  .copyBtn .copyLink {
    display: block;
  }

  .footerMargin {
    margin-bottom: 100px;
  }

  .imageColumn {
    height: 80px;
    min-height: 80px;
    margin-right: 8px;
  }

  .orderItem {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    border: 1px solid #e7e4e0;
    padding: 1em;
  }

  .thumbContainer {
    width: 45%;
    height: 120px;
  }

  .itemContainer {
    width: 70%;
  }

  .orderDivider {
    margin: 1em 0 1em 0;
  }

  .divider {
    display: none;
  }

  .header {
    display: none;
  }

  .mobileHeader {
    display: inline;
    font-weight: bolder;
  }

  .column {
    padding: 2px 3px 2px 3px;
    align-items: center;
  }

  .selectedOrder {
    height: 160px;
  }
}