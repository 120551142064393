.field {
  margin: 0.5em 0;
}

.field label {
  text-align: right;
  text-transform: uppercase;
  width: 10em;
}

.radio {
  composes: flex-row from '../../../../index.css';
  padding: 1em;
  padding-left: 1em;
  text-align: center;
}

.checkbox {
  composes: flex-row from '../../../../index.css';
  align-items: center;
  cursor: pointer;
  font-size: 0.8em;
  margin: 0.5em;
}

.checkboxDisabled {
  composes: checkbox;
  cursor: default;
  opacity: 0.5;
}

.checkboxInput {
  border: 3px solid #404040;
  height: 1em;
  position: relative;
  width: 1em;
}

.checkboxInputChecked {
  composes: checkboxInput;
}

.checkboxInputChecked:after {
  border-bottom: 3px solid #d42b1e;
  border-right: 3px solid #d42b1e;
  content: "";
  height: 1.5em;
  left: 0.6em;
  position: absolute;
  top: -0.95em;
  transform: rotate(40deg);
  width: 0.4em;
}

.checkboxLabel {
  margin: 0 0 0 0.5em;
  white-space: nowrap;
}