.steps {
  composes: flex-row from '../../../index.css';
  background-color: #fff;
  border-top-style: solid;
  border-top-width: 3px;
  box-shadow: 0 7px 7px rgba(0,0,0,0.2);
}

.tab {
  composes: flex-row from '../../../index.css';
  align-items: center;
  cursor: pointer;
  position: relative;
}

.tabDisabled {
  composes: tab;
  cursor: default;
}

.label {
  flex: 1;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 40px 10px 25px;
  z-index: 2;
}

.labelUnderline {
  display: none
}

.activeLabelUnderline {
  bottom: -4px;
  height: 4px;
  position: absolute;
  transform: skew(-45deg);
  transform-origin: bottom;
  left: -26px;
  width: calc(100% + 2px);
}

.tabDisabled .label {
  color: #ccc;
}

.arrow {
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 30px;
}

.arrow:after {
  border-color: #ccc;
  border-style: solid;
  border-width: 0 2px 2px 0;
  content: "";
  padding-top: 50px;
  position: absolute;
  right: 11px;
  top: calc(50% - 27px);
  transform: rotate(-45deg);
  width: 50px;
}

.uiLarge {
  display: block;
}
.uiSmall {
  display: none;
}


/* Smaller than 900 */
@media only screen and (max-width: 900px) {
  .label {
    padding: 10px 30px 10px 15px;
  }
}

/* Smaller than 800 */
@media only screen and (max-width: 800px) and (orientation: portrait) {
  .uiLarge {
    display: none;
  }
  .uiSmall {
    display: block;
  }
}

/* Smaller than 615 */
@media only screen and (max-width: 615px) and (orientation: portrait) {
  .tab {
    flex: 1 0 auto;
    min-width: 10px;
  }

  .label {
    font-size: 11px;
    padding: 10px 14px 10px 4px;
  }

  .tab:last-child .arrow {
    display: none;
  }

  .tab:last-child .activeLabel:after {
    width: calc(100% + 26px);
  }

  .activeLabelUnderline {
    left: -19px;
    transform: skew(-35deg);
  }

  .arrow:after {
    border-width: 0 1px 3px 0;
    top: calc(50% - 16px);
    transform: skewY(-55deg) rotate(-16deg);
    width: 30px;
  }
}
