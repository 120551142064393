.sports {
  composes: flex-one from '../../../index.css';
  align-items: center;
  display: flex;
  overflow: auto;
  height: 100%;
  padding: 50px 0 0 0;
  position: relative;
  width: 100%;
}

.centered {
  max-width: 1200px; 
  margin: 0 auto;
}

.hidden {
  composes: hidden from '../../../index.css';
}

.list {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-around;
  overflow: auto;
  width: 100%;
}

.item {
  pointer-events: all;
  text-align: center;
  margin: 2em 0;
  flex: 1 0 33%
}

.item > span.listFontIcon {
  cursor: pointer;
}

.fontIcon {
  font-size: 15.6em;
  -webkit-transition: color 1s, font-size 0.5s;
  -moz-transition: color 1s, font-size 0.5s;
  -o-transition: color 1s, font-size 0.5s;
  transition: color 1s, font-size 0.5s;
  transition-timing-function: ease-in-out;
}

/* Want them to be bigger by default vs. bigger on hover */
.fontIcon:hover {
  filter: brightness(110%);
}

.listFontIcon {
  composes: fontIcon;
  text-shadow: #d0d0d0 1px 2px;
}

.iconWithoutGrayGoods {
  opacity: 0.5;
}

@media only screen and (max-width: 1100px) {
  .listFontIcon {
    font-size: 12em;
  }
}

@media only screen and (max-width: 900px) {
  .listFontIcon {
    font-size: 10em;
  }
}

@media only screen and (max-width: 750px) {
  .sports .list {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
  }

  .sports .list .item {
    margin: 1em 0;
    align-items: center;
    display: flex;
  }
}