.coachWrapper {
    max-height: 450px;
    height: 100%;
    overflow: auto;
}

.coachInfoContainer {
    padding: 1em;
    border: 1px solid #e7e4e0;
    display: flex;
    flex-direction: row;
    margin: 1em;
}

.coachInfoContainer:hover {
    background-color: #fff6da;
}

.coachInfoContainer .personalInfo, .coachInfoContainer .addressInfo {
    width: 47%;
    display: flex;
    flex-direction: column;
    font-size: 1em;
    padding-left: 5px;
    color: #575757;
    font-family: 'proxima_novaregular';
}

.coachInfoContainer .mainText {
    font-size: 1.2em;
}

.coachInfoContainer .selectOption {
    width: 6%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.confirmButton {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 1em;
}

.addCoachInfo {
    border: none !important;
    background-color: transparent;
    margin-right: 5px;
}

.confirmButton>button {
    color: #fff;
    border-style: solid;
    border-width: 2px;
    text-transform: uppercase;
    height: 38px;
}

.radioOptions {
    composes: flex-row from '../../../../index.css';
}

.orLabel {
    composes: flex-one from '../../../../index.css';
    padding: 1em;
    text-align: center;
}

.radioField {
    composes: flex-one from '../../../../index.css';
}

.checkboxLabel {
    margin: 0 0 0 0.5em;
    white-space: nowrap;
}