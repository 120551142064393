.importRoster .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.importRoster .uploadRosterButton {
    background-color: #fff;
    border-style: solid;
    border-width: 2px;
    display: inline-block;
    margin: 1em 0em 0em 0em;
    text-transform: uppercase;
    padding: 1em 2.5em;
}

.importRoster .supportedFiles {
    padding: 0.5em 0em 2em 0em;
    font-style: italic;
}

.importRoster .downloadTemplateLink {
    text-transform: uppercase;
    padding-bottom: 2em;
    text-decoration: underline;
    cursor: pointer;
}

.importRoster .availableSizes {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.importRoster .tableWrap {
    flex: 1;
    min-height: 10px;
    overflow: auto;
    padding: 1px;
}

.importRoster table {
    border-collapse: collapse;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.importRoster tr:nth-of-type(odd),
.importRoster tr:nth-of-type(odd) input {
    background-color: #f0f0f0;
}

.importRoster th {
    background-color: #fff;
    font-family: 'proxima_novalight';
    font-style: italic;
    font-weight: 600;
    padding: 0.5em;
    position: sticky;
    top: 0;
    z-index: 2;
    width: 150px;
    text-align: center;
}

.importRoster td {
    text-align: center;
    width: 150px;
}

.importRoster .sizesContainer {
    padding-bottom: 1em;
}

.importRoster .footer {
    padding-top: 1em;
    margin: 2em 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e5e7ea;
}

.importRoster .footer .downloadLink {
    padding-top: 0.5em;
    padding-bottom: 0.25em;
    cursor: pointer;
    text-decoration: underline;
}