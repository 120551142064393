.carousel {
  display: flex;
  margin: 0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 7px;
}

.carouselHidden {
  composes: carousel;
  display: none;
}

.thumbChiclet {
  cursor: pointer;
  height: 63px;
  margin: 0;
  min-width: 63px;
  width: 63px;
}
